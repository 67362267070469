import { cn } from '@collection-platform-frontend/shared';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, Fragment, ReactNode, useCallback, useId } from 'react';

export type ModalProps = {
  children?: ReactNode;
  overlayClassName?: string;
  contentClassName?: string;
  show: boolean;
  fullScreen?: boolean;
  hideXmark?: boolean;
  unclosable?: boolean;
  isShow?: (value: boolean) => void;
};

export const Modal: FC<ModalProps> = ({
  children = <div />,
  overlayClassName,
  contentClassName,
  show,
  fullScreen = false,
  hideXmark = false,
  unclosable,
  isShow = () => undefined,
}) => {
  const onClose = useCallback(() => {
    if (unclosable) {
      return null;
    }
    return isShow(false);
  }, [unclosable, isShow]);

  const modalId = useId();
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        id={`headlessui-dialog-${modalId}`}
        as="div"
        className={cn('fixed inset-0 z-popup', {
          'cursor-progress': unclosable,
        })}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              id={`headlessui-dialog-overlay-${modalId}`}
              className={cn(
                'fixed inset-0 backdrop-blur-sm bg-[rgba(0,0,0,0.6)] dark:bg-[rgba(91,112,131,0.4)]',
                overlayClassName,
              )}
            />
          </Transition.Child>
          <div
            className={cn('fixed inset-0 ', { 'overflow-y-auto': !fullScreen })}
          >
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cn(
                    'relative w-[80%] max-w-7xl overflow-hidden text-left align-middle transition-all transform',
                    {
                      'bg-white dark:bg-zinc-900 shadow-xl rounded-2xl':
                        !fullScreen,
                    },
                    contentClassName,
                  )}
                >
                  {children}
                  {!fullScreen && !hideXmark && (
                    <div
                      className={cn(
                        'absolute cursor-pointer right-4 top-4 text-zinc-800 dark:text-zinc-100',
                        {
                          'cursor-progress': unclosable,
                        },
                      )}
                      onClick={onClose}
                    >
                      <XMarkIcon className="w-8 h-8" />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {fullScreen && !hideXmark && (
            <div
              className={cn(
                'absolute cursor-pointer text-zinc-100 right-4 top-4',
                {
                  'cursor-progress': unclosable,
                },
              )}
              onClick={onClose}
            >
              <XMarkIcon className="w-8 h-8" />
            </div>
          )}
        </div>
      </Dialog>
    </Transition>
  );
};
