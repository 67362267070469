import { FilterOption, Genre } from '@collection-platform-frontend/api';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';

import { Input } from '../../components/input/input';
import { debounce } from '../../libs/debounce';
import {
  DropFilterCategoriesModal,
  IRefHomeFilterModal,
} from '../modal/drop-filter-categories';
import {
  DropFilterItemListModal,
  IRefItemListModal,
} from '../modal/drop-filter-item-list';

type Props = PropsWithChildren<{
  filterOptions: FilterOption[];
  currentFilter?: {
    category?: string;
    genres?: (Genre & { checked?: boolean })[];
  };
  onSubmit: ({
    category,
    genres,
    searchText,
  }: {
    category?: string;
    genres?: Genre[];
    searchText?: string;
  }) => void;
}>;

export const HomeFilterSearch: FC<Props> = ({
  filterOptions,
  currentFilter,
  onSubmit = () => null,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const dropFilterCategoriesModalRef = useRef<IRefHomeFilterModal>(null);
  const dropFilterItemListModalRef = useRef<IRefItemListModal>(null);

  const openDropFilterCategoriesModal = () => {
    dropFilterCategoriesModalRef.current.toggleModal();
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => onSubmit({ searchText: value }), 1000),
    [],
  );

  const handleDropFilterItemSelected = (item: FilterOption) => {
    // Create a map of values in array B for faster lookup
    const mapCurrentGenres = currentFilter?.genres?.reduce((acc, obj) => {
      acc[obj?.value] = obj?.checked;
      return acc;
    }, {});

    dropFilterItemListModalRef.current?.setInitialState({
      title: item?.name || '',
      listItems: item?.genres?.map((obj) => ({
        ...obj,
        checked: mapCurrentGenres?.[obj?.value] || false,
      })),
    });

    dropFilterItemListModalRef.current?.toggleModal();
  };

  const handleSubmitFilter = (category: string, genres: Genre[]) => {
    dropFilterItemListModalRef.current?.toggleModal();
    dropFilterCategoriesModalRef.current?.toggleModal();
    onSubmit?.({ category, genres });
  };

  const onChangeSearchText = (searchText: string) => {
    setSearchText(searchText);
    debouncedSearch(searchText);
  };

  return (
    <div className="flex flex-row items-center justify-center gap-4 sm:px-0 xl:px-40 sm:mx-0 xl:mx-2">
      <div className="flex grow sm:grow-0 items-center md:justify-center md:gap-4">
        <Typography className="hidden sm:block">検索ワード：</Typography>
        <Input
          className="h-10 border-cloudear-blue focus-within:border-cloudear-blue"
          inputClassName="p-[5px] placeholder-gray-300 text-sm w-full border-cloudear-blue sm:min-w-[350px]"
          value={searchText}
          fullWidth
          placeholder="フリー検索"
          onChangeValue={onChangeSearchText}
        />
      </div>
      <Button
        slim
        className="w-fit text-cloudear-blue border-cloudear-blue min-w-5"
        onClick={openDropFilterCategoriesModal}
      >
        <FunnelIcon className="w-4 h-4" />
      </Button>

      <DropFilterCategoriesModal
        ref={dropFilterCategoriesModalRef}
        onSelectItem={handleDropFilterItemSelected}
        filterOptions={filterOptions}
      />
      <DropFilterItemListModal
        ref={dropFilterItemListModalRef}
        onSubmit={handleSubmitFilter}
        onReset={() => dropFilterItemListModalRef.current?.toggleModal()}
      />
    </div>
  );
};

HomeFilterSearch.displayName = 'HomeFilterSearch';
