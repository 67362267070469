import { Button, Typography } from '@collection-platform-frontend/ui';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Modal as CloudearModal } from '../../components/modal/modal';

interface SensitiveContentModalProps {
  onCallback: (value: boolean) => void;
}

export interface IRefSensitiveContentModal {
  toggleModal: () => void;
}

export const CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY =
  'CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY';

export enum SensitiveContentRememberCheckbox {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

const getPrevState = () => {
  const state = localStorage.getItem(CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY);
  if (
    state === SensitiveContentRememberCheckbox.CHECKED ||
    state === SensitiveContentRememberCheckbox.UNCHECKED
  ) {
    return false;
  } else {
    return true;
  }
};

export const SensitiveContentAlertModal = forwardRef<
  IRefSensitiveContentModal,
  SensitiveContentModalProps
>(({ onCallback = () => null }, ref) => {
  const [isDisplay, setIsDisplay] = useState(getPrevState());
  const [rememberMe, setRememberMe] = useState(false);

  const toggleCheckbox = () => {
    setRememberMe(!rememberMe);
  };

  const toggleDisplayModal = () => {
    setIsDisplay(!isDisplay);
  };

  useImperativeHandle(ref, () => ({
    toggleModal: toggleDisplayModal,
  }));

  const disallowedSensitiveContent = () => {
    if (rememberMe) {
      localStorage.setItem(
        CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY,
        SensitiveContentRememberCheckbox.UNCHECKED,
      );
    }
    toggleDisplayModal();
    onCallback(false);
  };

  const allowedSensitiveContent = () => {
    if (rememberMe) {
      localStorage.setItem(
        CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY,
        SensitiveContentRememberCheckbox.CHECKED,
      );
    }
    toggleDisplayModal();

    onCallback(true);
  };

  return (
    <>
      <CloudearModal
        show={isDisplay}
        isShow={toggleDisplayModal}
        contentClassName="sm:max-w-lg h-full select-none"
      >
        <div className=" p-6 overflow-hidden text-center transition-all transform shadow-xl max-w-[550px] bg-wallet-light-base dark:bg-wallet-base text-wallet-light-primary dark:text-wallet-primary rounded-3xl">
          <Typography variant="h2" className="pb-8">
            CLOUDEAR・グラビアページ掲載確認
          </Typography>
          <div className="pb-4 text-center ">
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: 'グラビアページの表示を行いますか',
                }}
              />
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Button
              className="border-hidden w-fit focus-visible:!outline-none"
              slim
              onClick={disallowedSensitiveContent}
            >
              いいえ
            </Button>
            /
            <Button
              className="border-hidden w-fit focus-visible:!outline-none"
              slim
              onClick={allowedSensitiveContent}
            >
              はい
            </Button>
          </div>
          <div className="flex justify-center items-center pt-4">
            <input
              checked={rememberMe}
              id="remember-checkbox"
              type="checkbox"
              className="text-wallet-light-primary dark:text-wallet-primary rounded-[4px] px-2 py-2 mr-3 bg-wallet-light-base dark:bg-wallet-base focus:!outline-none focus:!ring-0 focus:!shadow-none"
              onClick={toggleCheckbox}
            />
            <label htmlFor="remember-checkbox" className="">
              今後この確認をしない
            </label>
          </div>
        </div>
      </CloudearModal>
    </>
  );
});

SensitiveContentAlertModal.displayName = 'SensitiveContentAlertModal';
