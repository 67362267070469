export function debounce(
  func: (...args: Array<unknown>) => unknown,
  ms: number,
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: Array<unknown>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, ms);
  };
}
