import { cn } from '@collection-platform-frontend/shared';
import {
  Button,
  Image,
  Typography,
  UserMenuButton,
  WalletUser,
} from '@collection-platform-frontend/ui';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  ChevronRightIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  WalletIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { AuthUser } from 'next-firebase-auth';
import {
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useRouter } from '../../libs/router';
import {
  CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY,
  IRefSensitiveContentModal,
  SensitiveContentAlertModal,
  SensitiveContentRememberCheckbox,
} from '../../views/modal/sensitive-content-alert';
import { Icons } from '../icons';

const stripeCustomerPortalURL: string =
  process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL_FOR_CLOUDEAR || '';
const sensitiveFilterEnabled =
  process.env.NEXT_PUBLIC_FEATURE_SENSITIVE_FILTER_RELEASED === 'true';

const links: HeaderLinkProps[] = [
  { type: 'separator', activeWithCustomer: true },
  {
    type: 'link',
    icon: <FlagIcon className="w-6 h-6" />,
    url: '/beginners',
    title: 'はじめての方へ',
    activeWithCustomer: false,
  },
  {
    type: 'link',
    icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
    url: 'https://support.cldr.jp/hc/ja',
    title: 'よくある質問',
    targetBlank: true,
    activeWithCustomer: false,
  },
  { type: 'separator', activeWithCustomer: true },
  {
    type: 'link',
    icon: <ArchiveBoxIcon className="w-6 h-6" />,
    url: '/collection',
    title: 'マイコレクション',
    activeWithCustomer: true,
  },
  // {
  //   type: 'link',
  //   icon: <Cog6ToothIcon className="w-6 h-6" />,
  //   url: 'http://google.com',
  //   title: 'ユーザー設定',
  //   activeWithCustomer: true,
  // },
];

if (stripeCustomerPortalURL) {
  links.push({
    type: 'link',
    icon: <WalletIcon className="w-6 h-6" />,
    url: stripeCustomerPortalURL,
    title: '支払い設定',
    targetBlank: true,
    activeWithCustomer: true,
  });
}

type SlideOverProps = {
  title?: ReactNode;
  open: boolean;
  onClose: () => void;
};

const SlideOver: FC<PropsWithChildren<SlideOverProps>> = ({
  title,
  open,
  children,
  onClose,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-cover" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="-translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="-translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-lg pointer-events-auto">
                  <div className="h-full p-0 sm:p-6">
                    <div className="flex flex-col w-full h-full p-6 bg-white shadow-xl sm:rounded-3xl">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-wallet-light-primary">
                          {title}
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <button
                            type="button"
                            className="p-2 transition-colors rounded-md text-wallet-light-primary hover:text-zinc-600 focus:outline-none focus:ring-0"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="relative flex-1 mt-6">
                        <div className="absolute inset-0">{children}</div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

type HeaderLinkProps = {
  type: 'link' | 'separator';
  icon?: ReactNode;
  url?: string;
  title?: string;
  targetBlank?: boolean;
  activeWithCustomer?: boolean;
};

type SlideMenuProps = {
  className?: string;
  customer?: WalletUser;
  onLogin?: () => void;
  onLogout?: () => void;
};

export const SlideMenu: FC<SlideMenuProps> = ({
  className,
  customer,
  onLogin,
  onLogout,
}) => {
  const { push } = useRouter();
  const [open, isOpen] = useState<boolean>(false);

  const onOpen = useCallback(() => {
    isOpen(true);
  }, [isOpen]);

  const onClose = useCallback(() => {
    isOpen(false);
  }, [isOpen]);

  const onLink = useCallback(
    (href: string) => {
      const isExternalLink = !href?.startsWith('/');
      if (isExternalLink) {
        window.open(href, '_blank');
      } else {
        push(href);
      }
    },
    [push],
  );

  return (
    <div className={className}>
      <Bars3Icon
        className="w-8 h-8 cursor-pointer text-zinc-600 hover:text-zinc-800 "
        onClick={onOpen}
      />

      <SlideOver open={open} onClose={onClose}>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col space-y-4">
            {customer && (
              <div className="flex gap-3">
                <Image
                  className="w-12 h-12"
                  imageClassName="rounded-full"
                  skeletonClassName="rounded-full"
                  layout="fill"
                  src={customer.profileIconUrl}
                  alt="profile"
                />
                <div className="flex flex-col justify-center">
                  <Typography variant="h3">{customer.nickName}</Typography>
                  <Typography variant="body" className="text-wallet-secondary">
                    {customer.email}
                  </Typography>
                </div>
              </div>
            )}

            {links.map((o, i) => {
              const {
                type,
                icon,
                url,
                title,
                activeWithCustomer,
                targetBlank,
              } = o;
              if (activeWithCustomer && !customer) {
                return null;
              }

              if (type === 'separator') {
                return <hr key={i} className="mt-2 mb-1" />;
              }

              return (
                <div
                  key={i}
                  onClick={() => onLink(url)}
                  className="flex items-center justify-between p-2 rounded-md cursor-pointer hover:text-wallet-secondary"
                >
                  <div className="flex items-center gap-2">
                    {icon}
                    <Typography variant="h2">{title}</Typography>
                  </div>

                  {targetBlank ? (
                    <ArrowTopRightOnSquareIcon className="w-6 h-6" />
                  ) : (
                    <ChevronRightIcon className="w-6 h-6" />
                  )}
                </div>
              );
            })}
          </div>
          <Button
            primary={!customer}
            className={cn({ 'bg-cloudear-blue': !customer })}
            onClick={customer ? onLogout : onLogin}
          >
            {customer ? 'ログアウト' : 'ログイン/新規登録'}
          </Button>
        </div>
      </SlideOver>
    </div>
  );
};

const menus = [{ label: 'マイコレクション', href: '/collection' }];

type HeaderProps = {
  authUser?: AuthUser;
  user?: WalletUser;
  onBack?: () => void;
  onLink?: (value: string) => void;
  onLogin?: () => void;
  onLogout?: () => void;
  onFilter?: (value: boolean) => void;
};

const SimpleHeader: FC<HeaderProps> = ({
  authUser,
  user,
  onBack,
  onLink,
  onLogout,
}) => {
  return (
    <header className="fixed flex items-center justify-center w-full backdrop-blur-[20px]">
      <div className="flex items-center justify-between w-full max-w-6xl px-4 py-4">
        <Icons.CloudearLogo className="cursor-pointer" onClick={onBack} />
        {authUser ? (
          <UserMenuButton
            user={user}
            menus={menus}
            onLink={onLink}
            onLogout={onLogout}
          />
        ) : (
          <div className="h-[40px]" />
        )}
      </div>
    </header>
  );
};

const RenewalHeader: FC<HeaderProps> = ({
  authUser,
  user,
  onBack,
  onLogin,
  onLogout,
  onFilter = () => null,
}) => {
  const confirmationFilterModalRef = useRef<IRefSensitiveContentModal>(null);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    const checked =
      localStorage.getItem(CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY) ===
      SensitiveContentRememberCheckbox.CHECKED;
    setVisibility(checked);
  }, []);

  const onToggleModal = () => {
    confirmationFilterModalRef.current.toggleModal();
  };

  const onApplyDropsFilter = (value: boolean) => {
    setVisibility(value);

    // Apply drops filter
    onFilter(value);
  };

  return (
    <header className="fixed flex items-center justify-center w-full bg-wallet-light-base/80 backdrop-blur-[20px]">
      <div className="flex items-center justify-between w-full px-4 py-4 max-w-7xl">
        <div className="flex items-center hidden sm:flex gap-6">
          <Icons.CloudearLogo
            className="cursor-pointer hidden sm:block"
            onClick={onBack}
          />
          {sensitiveFilterEnabled ? (
            <>
              <Typography>グラビア表示：</Typography>
              <div className="w-32">
                <div className="flex items-center">
                  <label className="relative cursor-pointer">
                    <input
                      checked={visibility}
                      type="checkbox"
                      className="sr-only peer"
                      onChange={onToggleModal}
                    />
                    <div className="w-[53px] h-7 flex items-center bg-gray-300 rounded-full text-[9px] peer-checked:text-wallet-light-primary text-gray-300 font-extrabold after:flex after:items-center after:justify-center peer after:content-['Off'] peer-checked:after:content-['On'] peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-cloudear-blue"></div>
                  </label>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="flex items-center block sm:hidden gap-4">
          <Icons.CloudearTrademarkLogo
            className="cursor-pointer block sm:hidden"
            onClick={onBack}
          />
          <div className="w-auto">
            <div className="flex items-center">
              <label className="relative cursor-pointer">
                <input
                  checked={visibility}
                  type="checkbox"
                  className="sr-only peer"
                  onChange={onToggleModal}
                />
                <div className="w-[53px] h-7 flex items-center bg-gray-300 rounded-full text-[9px] peer-checked:text-wallet-light-primary text-gray-300 font-extrabold after:flex after:items-center after:justify-center peer after:content-['Off'] peer-checked:after:content-['On'] peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-cloudear-blue"></div>
              </label>
            </div>
          </div>
        </div>

        <div className="flex gap-4 items-center">
          {authUser?.id ? (
            <Link href="/collection">
              <div className="flex gap-3">
                <Image
                  className="w-12 h-12"
                  imageClassName="rounded-full"
                  skeletonClassName="rounded-full"
                  layout="fill"
                  src={user?.profileIconUrl}
                  alt="profile"
                />
                <div className="flex-col justify-center hidden sm:flex">
                  <Typography variant="h3">{user?.nickName}</Typography>
                </div>
              </div>
            </Link>
          ) : (
            <Button
              className="w-fit rounded-2xl flex justify-center items-center bg-cloudear-blue text-white"
              primary
              slim
              onClick={onLogin}
            >
              ログイン/新規登録
            </Button>
          )}
          <Button className="w-[48px] min-w-[48px] h-[48px] rounded-2xl p-0 flex justify-center items-center bg-transparent">
            <SlideMenu customer={user} onLogin={onLogin} onLogout={onLogout} />
          </Button>
        </div>
      </div>
      {sensitiveFilterEnabled ? (
        <SensitiveContentAlertModal
          ref={confirmationFilterModalRef}
          onCallback={onApplyDropsFilter}
        />
      ) : null}
    </header>
  );
};

const HeaderWithFilter = process.env.FEATURE_RENEWAL_TOP_RELEASED
  ? SimpleHeader
  : RenewalHeader;

export { HeaderWithFilter };
