import {
  BannerSlider,
  BannerSliderProps,
} from '@collection-platform-frontend/ui';

export type DropsBannersProps<T> = BannerSliderProps<T> & {
  fetching?: boolean;
};

export const DropsBanners = <T,>({
  source,
  itemRenderer,
  fetching,
  ...rest
}: DropsBannersProps<T>) => {
  if (fetching)
    return (
      <div
        role="status"
        className="w-full grid w-full grid-cols-1 gap-6 mb-12 md:grid-cols-4 gap-2 animate-pulse"
      >
        <div className="h-full bg-gray-300 rounded-3xl dark:bg-gray-700 min-h-[300px]"></div>
        <div className="h-full bg-gray-300 rounded-3xl dark:bg-gray-700 min-h-[300px]"></div>
        <div className="h-full bg-gray-300 rounded-3xl dark:bg-gray-700 min-h-[300px]"></div>
        <div className="h-full bg-gray-300 rounded-3xl dark:bg-gray-700 min-h-[300px]"></div>
      </div>
    );
  return (
    <BannerSlider<T> source={source} itemRenderer={itemRenderer} {...rest} />
  );
};

DropsBanners.displayName = 'DropsBanners';
