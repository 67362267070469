import { FilterOption } from '@collection-platform-frontend/api';
import { cn } from '@collection-platform-frontend/shared';
import { Typography } from '@collection-platform-frontend/ui';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Modal as CloudearModal } from '../../components/modal/modal';

interface IPropsHomeFilterModal {
  onSelectItem: (value: unknown) => void;
  filterOptions: FilterOption[];
}

export interface IRefHomeFilterModal {
  toggleModal: () => void;
  setUnclosable: (value) => void;
}

export const DropFilterCategoriesModal = forwardRef<
  IRefHomeFilterModal,
  IPropsHomeFilterModal
>(({ filterOptions = [], onSelectItem = () => null }, ref) => {
  const [isDisplay, setIsDisplay] = useState(false);

  const [unclosable, setUnclosable] = useState(false);

  const [selected, setSelected] = useState(null);

  useImperativeHandle(ref, () => ({
    toggleModal: toggleDisplayModal,
    setUnclosable: setUnclosable,
  }));

  const toggleDisplayModal = () => {
    setIsDisplay(!isDisplay);
  };

  const onSelect = (item: FilterOption) => () => {
    onSelectItem(item);
  };

  return (
    <>
      <CloudearModal
        show={isDisplay}
        isShow={toggleDisplayModal}
        contentClassName="sm:max-w-lg h-full select-none"
        unclosable={unclosable}
      >
        <div className="overflow-hidden text-center transition-all transform bg-wallet-light-base dark:bg-wallet-base text-wallet-light-primary dark:text-wallet-primary">
          <Typography
            variant="h2"
            className="bg-wallet-primary py-5 text-center"
          >
            フィルター
          </Typography>
          <RadioGroup value={selected} onChange={setSelected}>
            <div className="p-12 text-center grid gap-4 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2">
              {filterOptions.map((item, index) => {
                return (
                  <RadioGroup.Option
                    key={`${item.name}_${index}`}
                    value={item}
                    onClick={onSelect(item)}
                    className={() =>
                      cn(
                        'relative flex cursor-pointer rounded-lg px-2 py-2 shadow focus:outline-none',
                      )
                    }
                  >
                    {({ checked }) => (
                      <>
                        <div className="w-full relative text-center">
                          <RadioGroup.Label
                            as="p"
                            className="select-none line-clamp-1"
                          >
                            {item?.name || ''}
                          </RadioGroup.Label>
                          {checked && (
                            <CheckCircleIcon className="absolute top-0 right-0 h-6 w-6 " />
                          )}
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                );
              })}
            </div>
          </RadioGroup>
        </div>
      </CloudearModal>
    </>
  );
});

DropFilterCategoriesModal.displayName = 'DropFilterCategoriesModal';
