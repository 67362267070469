import { Genre } from '@collection-platform-frontend/api';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Modal as CloudearModal } from '../../components/modal/modal';

interface IPropsItemListModal {
  onReset: () => void;
  onSubmit: (category: string, genres: Genre[]) => void;
}

export interface IRefItemListModal {
  toggleModal: () => void;
  setInitialState?: (state: { title?: string; listItems?: Genre[] }) => void;
}

export type DropsFilterItemOption = Genre & {
  checked?: boolean;
};

export const DropFilterItemListModal = forwardRef<
  IRefItemListModal,
  IPropsItemListModal
>((props, ref) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [listItems, setListItems] = useState<DropsFilterItemOption[]>([]);

  const handleSetInitialState = ({
    title,
    listItems,
  }: {
    title?: string;
    listItems?: DropsFilterItemOption[];
  }) => {
    if (title) setTitleModal(title);
    if (listItems) setListItems(listItems);
  };

  useImperativeHandle(ref, () => ({
    toggleModal: toggleDisplayModal,
    setInitialState: handleSetInitialState,
  }));

  const toggleDisplayModal = () => {
    setIsDisplay(!isDisplay);
  };

  const onReset = () => {
    if (props?.onReset) props.onReset();
  };

  const onSubmit = () => {
    if (props?.onSubmit) props.onSubmit(titleModal, listItems);
  };

  const onSelectItem = (item: Genre) => () => {
    setListItems(
      listItems.map((e) => {
        if (item?.value === e.value) {
          return { ...e, checked: !e?.checked };
        }
        return { ...e };
      }),
    );
  };

  return (
    <>
      <CloudearModal
        show={isDisplay}
        isShow={toggleDisplayModal}
        contentClassName="sm:max-w-xl min-h-full h-full select-none"
        overlayClassName="bg-black/0"
      >
        <div className="overflow-hidden text-center transition-all transform bg-wallet-light-base dark:bg-wallet-base text-wallet-light-primary dark:text-wallet-primary">
          <Typography
            variant="h2"
            className="bg-wallet-primary py-5 text-center"
          >
            {titleModal}
          </Typography>
          <div className="max-h-80 min-h-[11rem] overflow-auto">
            <div className="p-8 text-center grid gap-6 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2">
              {listItems.map((item, index) => (
                <div
                  key={`drop-filter-item-${item.value}-${index}`}
                  className="flex items-center pt-4"
                >
                  <input
                    checked={item.checked || false}
                    id={`checkbox-item-${item.value}-${index}`}
                    type="checkbox"
                    className="text-wallet-light-primary dark:text-wallet-primary rounded-[4px] px-3 py-3 mr-3 bg-wallet-light-base dark:bg-wallet-base focus:!outline-none focus:!ring-0 focus:!shadow-none"
                    onChange={onSelectItem(item)}
                  />
                  <label
                    htmlFor={`checkbox-item-${item.value}-${index}`}
                    className="relative flex w-full cursor-pointer rounded-lg px-2 py-2 shadow focus:outline-none select-none"
                  >
                    <p className="line-clamp-1">{item.value}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-wallet-primary flex items-center justify-center gap-4 p-6">
            <Button className="max-w-[100px]" slim onClick={onReset}>
              リセット
            </Button>
            <Button
              // disabled={listItems.every((e) => !e.checked)}
              className="max-w-[100px]"
              slim
              onClick={onSubmit}
              primary
            >
              OK
            </Button>
          </div>
        </div>
      </CloudearModal>
    </>
  );
});

DropFilterItemListModal.displayName = 'DropFilterItemListModal';
