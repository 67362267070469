import { cn } from '@collection-platform-frontend/shared';
import {
  ChangeEvent,
  FC,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  useCallback,
} from 'react';

type InputContaierProps = {
  className?: string;
  errorMessage?: string;
  fullWidth?: boolean;
};

const InputContainer: FC<PropsWithChildren<InputContaierProps>> = ({
  children,
  className,
  errorMessage,
  fullWidth,
}) => {
  return (
    <div
      className={cn('flex flex-col', {
        ' grow': !!fullWidth,
      })}
    >
      <div
        className={cn(
          'box-border flex items-center w-full px-4 bg-wallet-light-base dark:bg-wallet-base border rounded-2xl border-wallet-primary focus-within:border-wallet-inactive dark:border-wallet-inactive dark:focus-within:border-wallet-primary font-noto',
          {
            ' focus-within:border-wallet-light-error dark:border-wallet-error dark:focus-within:border-wallet-error':
              !!errorMessage,
          },
          className,
        )}
      >
        <div className="w-full">{children}</div>
      </div>
      {errorMessage && (
        <div className="pt-1 text-wallet-light-error dark:text-wallet-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  InputContaierProps & {
    forceUppercase?: boolean;
    inputClassName?: string;
    onChangeValue?: (value: string) => void;
  };

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      className,
      inputClassName,
      errorMessage,
      forceUppercase,
      fullWidth,
      onChange,
      onChangeValue,
      ...rest
    } = props;

    const _onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event);
        }

        if (onChangeValue) {
          let value = event.currentTarget.value;
          if (forceUppercase) {
            value = value.toUpperCase();
            event.currentTarget.value = value;
          }

          onChangeValue(value);
        }
      },
      [onChange, forceUppercase, onChangeValue],
    );

    return (
      <InputContainer
        className={className}
        errorMessage={errorMessage}
        fullWidth={fullWidth}
      >
        <input
          ref={ref}
          type="text"
          className={cn(
            'w-full text-wallet-light-primary dark:text-wallet-primary rounded-full px-[1px] py-3 bg-wallet-light-base dark:bg-wallet-base shadow-none border-none focus:!outline-none focus:!ring-0 focus:!shadow-none',
            {
              'border-wallet-light-error focus-within:border-wallet-light-error dark:border-wallet-error dark:focus-within:border-wallet-error':
                !!errorMessage,
            },
            inputClassName,
          )}
          {...rest}
          onChange={_onChange}
        />
      </InputContainer>
    );
  },
);
